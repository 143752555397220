import { Box, Container, Flex, Grid, Text } from "@theme-ui/components"
import Link from "../components/utils/link"
import React from "react"
import { getCategoryPath } from "../utils/path"
import ArticleThumb from "./articleThumb"

const CategorySection = ({ categories, articles }) => {
  function GetArticlesByCategoryId(categoryId, items) {
    function isSameCategory(article) {
      return article.categories.some(cat => cat.id === categoryId)
    }
    return articles.nodes.filter(isSameCategory).slice(0, items)
  }

  return (
    <Container>
      <Flex
        sx={{ flexWrap: "wrap", flexDirection: ["column", "column", "row"] }}
      >
        {categories.map(category => (
          <Box
            key={category.id}
            sx={{
              borderTop: "2px solid",
              borderColor: "dark",
              width: ["100%", "100%", `${category.homePageItems * 25}%`],
            }}
            pt={2}
          >
            <Text
              sx={{
                color: "primary",
                fontSize: [3],
                fontWeight: 500,
                display: "inline-block",
              }}
            >
              <Link
                to={getCategoryPath(category.slug, category.locale)}
                sx={{ "&hover:": { color: "dark" } }}
              >
                {category.name}
              </Link>
            </Text>
            <Grid columns={[1, 1, category.homePageItems]} pr={[0, 0, 3]}>
              {GetArticlesByCategoryId(category.id, category.homePageItems).map(
                article => (
                  <Box key={article.id}>
                    <ArticleThumb article={article} />
                  </Box>
                )
              )}
            </Grid>
          </Box>
        ))}
      </Flex>
    </Container>
  )
}

export default CategorySection
