import { Box, Container, Flex, Text } from "@theme-ui/components"
import Link from "../components/utils/link"
import React from "react"
import { getCategoryPath } from "../utils/path"
import ArticleThumb from "./articleThumb"
import SwiperCore, { Pagination, Mousewheel, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
SwiperCore.use([Mousewheel, Pagination, A11y])

const CategorySectionMobile = ({ categories, articles }) => {
  function GetArticlesByCategoryId(categoryId, items) {
    function isSameCategory(article) {
      return article.categories.some(cat => cat.id === categoryId)
    }
    return articles.nodes.filter(isSameCategory).slice(0, items)
  }

  return (
    <Container>
      <Flex sx={{ flexWrap: "wrap", flexDirection: ["column"] }}>
        {categories.map(category => (
          <Box
            key={category.id}
            sx={{
              borderTop: "2px solid",
              borderColor: "dark",
              width: "100%",
            }}
            pt={2}
          >
            <Text
              sx={{
                color: "primary",
                fontSize: [3],
                fontWeight: 500,
                display: "inline-block",
              }}
            >
              <Link
                to={getCategoryPath(category.slug, category.locale)}
                sx={{ "&hover:": { color: "dark" } }}
              >
                {category.name}
              </Link>
            </Text>
            <Box>
              <Swiper
                spaceBetween={32}
                slidesPerView={1}
                pagination={{ clickable: true }}
                mousewheel={true}
              >
                {GetArticlesByCategoryId(category.id).map(article => (
                  <SwiperSlide key={article.id}>
                    <ArticleThumb article={article} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </Box>
        ))}
      </Flex>
    </Container>
  )
}

export default CategorySectionMobile
